



































import { Component, Vue, Watch } from 'vue-property-decorator'
import AlertaDeSistemaModule from '@/store/vuex/aplicacao/AlertaDeSistemaModule'
import NotificacaoGlobalService from '@/services/usuario/NotificacaoGlobalService'

@Component
export default class AlertaDeSistema extends Vue {
	set mostrarAlertaDeSistema(value: boolean) {
		AlertaDeSistemaModule.setShow(value)
	}

	get mostrarAlertaDeSistema() {
		return AlertaDeSistemaModule.mostrarAlertaDeSistema
	}

	get message() {
		return AlertaDeSistemaModule.message
	}

	@Watch('show')
	onShow(show: boolean) {
		show
			? document.addEventListener('keydown', this.atalhoDeSumir)
			: document.removeEventListener('keydown', this.atalhoDeSumir)
	}

	atalhoDeSumir(event: KeyboardEvent) {
		if (event.key !== 'Escape') return
		this.fecharAlerta()
		event.preventDefault()
		event.stopPropagation()
	}

	async fecharAlerta() {
		if (!this.mostrarAlertaDeSistema) return 

		await NotificacaoGlobalService.setarRecebido(this.message.id)

		this.mostrarAlertaDeSistema = false
	}
}

